<template>
  <div class="dashboard-container" v-loading="loading">
    <!-- 添加或编辑类别 -->
    <el-dialog :title="title" :visible="showDialog" @close="btnCancel">
      <!-- 表单 -->
      <el-form
        ref="perForm"
        :model="formdata"
        :rules="rules"
        label-width="200px"
      >

      
<el-form-item label="来源类型" prop="moduleName" v-if="!formdata.msgId">
       <el-radio v-model="formdata.moduleName" label='ABOUTUS' @change="change">关于我们</el-radio>
       <el-radio v-model="formdata.moduleName" label="VIPSERVICE" @change="change">会员服务信息</el-radio>
    
      </el-form-item>
              
      
       <el-form-item label="分类类型" prop="typeId"  v-if="formdata.moduleName=='ABOUTUS'|formdata.typeId">
          <el-select
            v-model="formdata.typeId"
            placeholder="请选择"
            @change="selectChange"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.typeName"
              :value="item.typeId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      <!-- <el-form-item label="文本内容" prop="content">
          <el-input
            v-model="formdata.content"
            placeholder="请输入文本内容"
            style="width: 50%"
          />
        </el-form-item> -->

      <el-form-item label="文本内容" prop="content" >
          <div style="line-height: normal">
            <uEditor v-model="formdata.content"></uEditor>
          </div>
        </el-form-item>
      
     
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
<el-dialog title="添加关于我们分类" :visible="dialog" @close="btnCancel1">
      <!-- 表单 -->
      <el-form
        ref="pForm"
        :model="fdata"
        :rules="rules1"
        label-width="200px">
        <el-form-item label="标题名称" prop="typeName">
          <el-input
            v-model="fdata.typeName"
            placeholder="请输入标题名称"
            style="width: 50%"
          />
        </el-form-item>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK1">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel1">取消</el-button>
        </el-col>
        
      </el-row>
      
    </el-dialog>
    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="12" align="left">
          <div style="display: flex; width: 60%">
            <el-select
              v-model="typeId"
              clearable
              placeholder="请选择关于我们分类"
              @clear="clear"
              @change="selectChange2"
              style="wdith: 300px; margin-right: 10px"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.typeName"
                :value="item.typeId"
              >
              </el-option>
            </el-select>
              <el-select
              clearable
              @clear="clear1"
              v-model="moduleName"
              placeholder="请选择模块名称"
              @change="selectChange3"
              style="wdith: 300px;"
            >
              <el-option
                v-for="(item, index) in res"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="12" align="right">
             <el-button class="el-icon-edit" type="warning" @click="addfl"
            >管理关于我们分类</el-button
          >
          <el-button class="el-icon-edit" type="primary" @click="addSchoolInfo"
            >添加通用文本信息</el-button
          >
        </el-col>
      </el-row>
    </div>

    <!-- 主体内容 -->

    <div class="tab">
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="list"
        border
        style="width: 100%"
        ref="list"
        class="emp_table"
        v-if="list"
      >
        <el-table-column
          align="center"
          prop="content"
          label="文本内容"
           class="myNote"
           :show-overflow-tooltip="true"
          
        >
        <template slot-scope="scope">
                            <div v-html="scope.row.content" class="img myNote"  :show-overflow-tooltip="true" ></div>
                        </template>

        </el-table-column>
        
        <!-- <el-table-column
          align="center"
          prop="msgId"
          label="文本Id"
          :show-overflow-tooltip="true"
        /> -->
        <el-table-column
          align="center"
          prop="moduleName"
          label="模块信息"
          :show-overflow-tooltip="true"
        >
         <template slot-scope="scope">
            {{ scope.row.moduleName =='ABOUTUS'? '关于我们':'会员服务信息' }}
          </template>
        </el-table-column>
        <!-- <el-table-column
          align="center"
          prop="typeName"
          label="分类名称"
          :show-overflow-tooltip="true"
        >
        </el-table-column> -->
        

        <el-table-column
          align="center"
          prop="createTime"
          label="创建时间"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime | getLocalTime }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作" width="251px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="updateSchoolInfo(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="delCommonMsg(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <!-- <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.pageSize"
        @pagination="getCommonMsgList"
      /> -->
    </div>
  </div>
</template>

<script>
import {
   addType,
  getCommonMsgByTypeId,
  getCommonMsgByModuleName,
  getTypes,
  getCommonMsgList,
  addCommonMsg,
 modifyCommonMsg,
  delCommonMsg,
} from "../../request/http";

export default {
  data() {
    return {
        dialog: false,
      fdata: {
        typeName: "",
        typeGroup: "ABOUTUS",
      },
      imageUrl:'',
      moduleName:'',
      typeId: "",
      title: "", //弹出框标题
      loading: true, //加载动画
      showDialog: false, //显示隐藏
      list: [], // 列表
      options: [],
      total: 0, //总条数
      res:[
          {
            label:'关于我们',
            value:'ABOUTUS'
          },
          {
            label:'会员服务信息',
            value:'VIPSERVICE'

          }
      ],
      queryParams: {
        page: 1,
        pageSize: 10,
      },
      formdata: {
        moduleName:'',
        typeId:null,
        content:'',
      },
      searchtitle: "", //搜索名称
      rules: {
     
        
        moduleName: [
          { required: true, message: "模块信息不能为空", trigger: "blur" },
        ],
        content: [
          { required: true, message: "文本内容不能为空", trigger: "blur" },
        ],
        
          typeId: [
          { required: true, message: "分类Id不能为空", trigger: "blur" },
        ],
    
      },
       rules1: {
        typeName: [
          { required: true, message: "分类名称不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getCommonMsgList();
   this.getList()
  },
  methods: {
     addfl(){
         this.$router.push({
        path: "/managementabout",
       
      });
     },
     btnOK1() {
      this.$refs.pForm
        .validate()
        .then(() => {
            return addType(this.fdata);
        })
        .then(() => {
          //  提示消息
            this.$message.success("添加分类成功");
            this.getList();
         

          this.dialog = false;
        });
    },
 btnCancel1() {
     
      this.fdata = {};
      this.$refs.pForm.resetFields();
      this.dialog =false
    },

   async getList() {
      let res = await getTypes({
        typeGroup: "ABOUTUS",
      });
      console.log(res);

      this.options = res.data;
    },
   async selectChange3(val){
      this.typeId =''
  this.moduleName =val
  console.log(this.moduleName);
  
   if(this.moduleName=='VIPSERVICE'){
         let {data,count} = await getCommonMsgByModuleName({
          moduleName:this.moduleName,
        })
       this.list=Array(data)
      }
        else{
          this.getCommonMsgList()
        }
        // this.total = count
   
    },
    async selectChange2(val) {
      this.moduleName =''
      this.typeId = val;
      console.log(this.typeId);
     
       if (this.moduleName ==''&&this.typeId!==''){
        let {data} = await getCommonMsgByTypeId({
         typeId: this.typeId
        })
      this.list=Array(data)
        
        // this.total = count
      }
      
    },
  async  clear (){
      this.typeId =''
      if(this.moduleName=='VIPSERVICE'){
         let {data,count} = await getCommonMsgByModuleName({
          moduleName:this.moduleName,
        })
       this.list=Array(data)
      }
      else {
         this.getCommonMsgList()
      }
    },
    async clear1(){
   this.moduleName =''
   if(this.typeId!==''){
      let {data} = await getCommonMsgByTypeId({
         typeId: this.typeId
        })
       this.list=Array(data)
   }
   else {
      this.getCommonMsgList()
   }
   
    },
    selectChange(value) {
      this.formdata.typeId = value;
      console.log(this.formdata.typeId);
    },
      async change(val){
      console.log(val);
      this.formdata.moduleName = val
      },
 
    //获取列表
    async getCommonMsgList() {
      this.queryParams.moduleName ='ABOUTUS'
      let { data } = await getCommonMsgList(this.queryParams);
      this.list=data
    
      console.log(this.list);
      this.loading = false;
    },

  
    // 打开添加的窗口
    addSchoolInfo() {
         this.editOradd = 0;
      let editOradd = this.editOradd;
      this.$router.push({
        path: "/addmessage",
        query: { editOradd },
      });
      // this.imageUrl=null
       
      // this.title = "添加通用文本信息";
      // this.showDialog = true;
    },
    // 打开编辑类别的窗口
    updateSchoolInfo(val) {
        this.editOradd = 1;
      let editOradd = this.editOradd;
      let obj = val;
      this.$router.push({
        path: "/addmessage",
        query: { editOradd, obj },
      });
      // console.log(val);
    
      // this.title = "编辑通用文本信息";
      // this.formdata = {
      //   typeId:val.typeId,
      //   msgId: val.msgId,
      //   content:val.content,
     
      // };
      // this.showDialog = true;
    },

    // 确认
    btnOK() {
      this.$refs.perForm
        .validate()
        .then(() => {
          if (this.formdata.msgId) {
           
            return modifyCommonMsg(this.formdata);
          } else {
            return addCommonMsg(this.formdata);
          }
        })
        .then(() => {
          //  提示消息
          if (this.formdata.msgId) {
            this.$message.success("编辑成功");
          } else {
            this.$message.success("新增成功");
            
          }

          this.getCommonMsgList();

          this.showDialog = false;
        });
    },

    //弹窗取消函数
    btnCancel() {

      this.formdata = {};
      this.$refs.perForm.resetFields();
      this.showDialog = false;
    },
    //删除信息
    async delCommonMsg(row) {
      try {
        await this.$confirm("确定要删除该资讯吗");
        await delCommonMsg({ msgId: row.msgId });
        const totalPage = Math.ceil(
          (this.total - 1) / this.queryParams.pageSize
        );
        this.queryParams.page =
          this.queryParams.page > totalPage ? totalPage : this.queryParams.page;
        this.queryParams.page =
          this.queryParams.page < 1 ? 1 : this.queryParams.page;
        this.getCommonMsgList();

        this.$message.success("删除成功");
      } catch (error) {
        console.log(error);
      }
    },

   
   
    
  },
};
</script>

<style scoped>
  /deep/.img img {
    width:100px!important
  }
  .myNote{
  display:-webkit-box;
  text-overflow:ellipsis;
  overflow:hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient:vertical;
}
</style>